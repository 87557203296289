<template>
    <div class="badge-17-wrapper" :style="((widget_data.widget.settings.badge_display == 'Embed' ? 'margin: auto;': ''))">
        <v-card
            elevation="0"
            class="reviews-badge"
            @click="$parent.showDialog()"
            rounded
            outlined
            flat
            :style="'padding-bottom: 0px; background-color: ' + ((widget_data.widget.settings.background_type == 'Transparent') ? 'transparent' : widget_data.widget.settings.background_color) + '; color: ' + widget_data.widget.settings.text_color + ';'"
        >
            <div class="clearfix p-2 pb-0 text-center">
                <div class="text-xs">
                    {{ ((widget_data.widget.settings.badge_17_title) ? widget_data.widget.settings.badge_17_title.replace('[reviews_nr]', widget_data.total_reviews).replace('[rating_nr]', widget_data.average_rating) : "Check out our " + widget_data.total_reviews + " reviews") }}
                </div>
            </div>

            <div class="clearfix">
                <div class="text-center">
                    <v-rating
                        class="reviews-rating"
                        :value="parseFloat(widget_data.average_rating)"
                        :color="widget_data.widget.settings.star_color"
                        dense
                        half-increments
                        readonly
                        size="22"
                    ></v-rating>
                </div>
            </div>

            <div class="clearfix p-2 pl-4 pb-0 text-center">
                <div style="display: inline-block; position: relative; margin-right: 5px;">
                    <div v-if="widget_data.widget.settings.platforms.length == 1">
                        <img v-for="(item, index) in widget_data.widget.settings.listings" :key="index" v-show="listingEnabled(item.id)"
                            :src="getIconUrl(item.url)"
                            class="listing-img"
                        >
                    </div>
                    <div v-else class="-mt-2">
                        <i class="mdi mdi-share-variant-outline" :style="'font-size: 20px; color: ' + widget_data.widget.settings.star_color"></i>
                    </div>
                </div>
                <div class="text-xs" style="display: inline-block; position: relative; vertical-align: super;">
                    {{ ((widget_data.widget.settings.badge_17_subtitle) ? widget_data.widget.settings.badge_17_subtitle.replace('[reviews_nr]', widget_data.total_reviews).replace('[rating_nr]', widget_data.average_rating) : "Our reviews") }}
                </div>
            </div>

            <div class="background-bottom" :style="'background:' + widget_data.widget.settings.badge17_bottom_color + ';'">
                {{ ((widget_data.widget.settings.badge_17_bottom_text) ? widget_data.widget.settings.badge_17_bottom_text.replace('[reviews_nr]', widget_data.total_reviews).replace('[rating_nr]', widget_data.average_rating) : "Help each other make better choices") }}
            </div>
        </v-card>

        <div v-if="widget_data.widget.subscription.stripe_id == 'starter'" class="reviews-footer" :style="'color: ' + widget_data.widget.settings.footer_text_color + '; text-align: center;'">
            Powered by <a href="https://reviewgrower.com/" target="review-grower-external" :style="'color: ' + widget_data.widget.settings.footer_link_color + ';'">Review Grower</a>
        </div>
    </div>
</template>

<script>
import Header from '@/views/panel/Business/Widgets/Render/Header.vue';
import Review from '@/views/panel/Business/Widgets/Render/Review.vue';
import urlParser from 'url-parse'

export default {
    props: ["widget_data", "fw"],

    components: {
        Header,
        Review
    },
    
    methods: {
        getHostname(url) {
            return urlParser(url).hostname.replace("www.", "")
        },
        getPathname(url) {
            return urlParser(url).pathname
        },
        getIconUrl(url) {
            return require('@/assets/images/review-services/www.' + this.getHostname(url) + ".png");
        },
        listingEnabled(id) {
            console.log('id', id);
            console.log('this.widget_data.widget.settings.platforms', this.widget_data.widget.settings.platforms);
            for (var i in this.widget_data.widget.settings.platforms) {
                if (this.widget_data.widget.settings.platforms[i] == id)
                    return true;
            }

            return false;
        }
    }
}
</script>

<style type="text/css" scoped>
    .badge-17-wrapper {
        width: 215px;
        padding-bottom: 10px;
    }

    .listing-img {
        width: 20px;
        height: 20px;
        /* border-radius: 50%; */
    }

    .background-bottom {
        padding: 10px;
        font-size: 11px;
        margin-top: 5px;
        text-align: center;
    }
</style>