<template>
    <div class="badge-5-wrapper" :style="((widget_data.widget.settings.badge_display == 'Embed' ? 'margin: auto;': ''))">
        <v-card
            elevation="0"
            class="reviews-badge"
            @click="$parent.showDialog()"
            rounded
            outlined
            flat
            :style="'border-radius: 8px !important; padding-bottom: 0px; background-color: ' + ((widget_data.widget.settings.background_type == 'Transparent') ? 'transparent' : widget_data.widget.settings.background_color) + '; color: ' + widget_data.widget.settings.text_color + ';'"
        >
            <div class="clearfix p-2 pb-0">
                <div class="float-left">
                    <div v-if="widget_data.widget.settings.platforms.length == 1">
                        <img v-for="(item, index) in widget_data.widget.settings.listings" :key="index" v-show="listingEnabled(item.id)"
                            :src="getIconUrl(item.url)"
                            class="listing-img"
                        >
                    </div>
                    <div v-else class="-mt-2">
                        <i class="mdi mdi-share-variant-outline" :style="'font-size: 25px; color: ' + widget_data.widget.settings.star_color"></i>
                    </div>
                </div>

                <div class="float-left pl-2 text-sm" style="margin-top: 0px;">
                    {{ ((widget_data.widget.settings.badge_8_title) ? widget_data.widget.settings.badge_8_title.replace('[reviews_nr]', widget_data.total_reviews).replace('[rating_nr]', widget_data.average_rating) : "Our reviews") }}
                </div>
            </div>

            <div class="clearfix p-2 pt-0">

                <div class="reviews-average -mb-2 pt-0" style="display: inline-block; line-height: normal;">
                    {{ widget_data.average_rating }}
                </div>

                <div style="display: inline-block; vertical-align: super;">
                    <v-rating
                        class="reviews-rating"
                        :value="parseFloat(widget_data.average_rating)"
                        :color="widget_data.widget.settings.star_color"
                        dense
                        half-increments
                        readonly
                        size="26"
                    ></v-rating>
                </div>
            </div>

            <div class="clearfix text-xs p-2 pt-0">
                {{ ((widget_data.widget.settings.badge_8_subtitle) ? widget_data.widget.settings.badge_8_subtitle.replace('[reviews_nr]', widget_data.total_reviews).replace('[rating_nr]', widget_data.average_rating) : "Rating based on our " + widget_data.total_reviews + " reviews.") }}
            </div>

            <div class="clearfix"></div>

            <div class="text-xs p-2 text-underline pt-0 pb-3">
                {{ ((widget_data.widget.settings.badge_8_link_text) ? widget_data.widget.settings.badge_8_link_text.replace('[reviews_nr]', widget_data.total_reviews).replace('[rating_nr]', widget_data.average_rating) : "Read reviews") }}
            </div>
        </v-card>

        <div v-if="widget_data.widget.subscription.stripe_id == 'starter'" class="reviews-footer" :style="'color: ' + widget_data.widget.settings.footer_text_color + '; text-align: center;'">
            Powered by <a href="https://reviewgrower.com/" target="review-grower-external" :style="'color: ' + widget_data.widget.settings.footer_link_color + ';'">Review Grower</a>
        </div>
    </div>
</template>

<script>
import Header from '@/views/panel/Business/Widgets/Render/Header.vue';
import Review from '@/views/panel/Business/Widgets/Render/Review.vue';
import urlParser from 'url-parse'

export default {
    props: ["widget_data", "fw"],

    components: {
        Header,
        Review
    },
    
    methods: {
        getHostname(url) {
            return urlParser(url).hostname.replace("www.", "")
        },
        getPathname(url) {
            return urlParser(url).pathname
        },
        getIconUrl(url) {
            return require('@/assets/images/review-services/www.' + this.getHostname(url) + ".png");
        },
        listingEnabled(id) {
            console.log('id', id);
            console.log('this.widget_data.widget.settings.platforms', this.widget_data.widget.settings.platforms);
            for (var i in this.widget_data.widget.settings.platforms) {
                if (this.widget_data.widget.settings.platforms[i] == id)
                    return true;
            }

            return false;
        }
    }
}
</script>

<style type="text/css" scoped>
    .badge-5-wrapper {
        width: 235px;
        padding-bottom: 10px;
    }

    .listing-img {
        width: 20px;
        height: 20px;
    }
</style>