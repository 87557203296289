<template>
    <div class="embed-slider-wrapper pl-3 pr-3" :style="
        'background-color: ' + ((widget_data.widget.settings.background_type == 'Transparent') ? 'transparent' : widget_data.widget.settings.background_color) + ';' +
        'margin-left: -' + widget_data.widget.settings.padding_between_reviews + ';'
    ">
        <Header :widget_data="widget_data" />

        <div v-show="widget_data.widget.settings.show_leave_a_review_button == 1" class="pt-0 mb-5" style="width: 160px; margin: auto; margin-bottom: 20px;">
            <div class="border-1 border-blue text-primary text-xs text-bold px-4 py-2 rounded cursor-pointer" @click="openReviewFunnel()" style="margin-top: -5px;"><i class="mdi mdi-star mr-2"> </i> WRITE A REVIEW</div>
        </div>

        <div class="clearfix"></div>

        <v-carousel height="auto">
            <template v-for="(item, index) in widget_data.reviews"> 
            <v-carousel-item v-if="(index + 1) % slider_columns === 1 || slider_columns === 1" 
                            :key="index"
            > 
                <v-row class="flex-nowrap" style="height:100%"> 
                <template v-for="(n,i) in slider_columns"> 
                    <template v-if="(+index + i) < widget_data.reviews.length"> 
                    <v-col :key="i">
                        <v-sheet v-if="(+index + i) < widget_data.reviews.length" 
                                height="100%"
                                color="transparent"
                        >
                            <Review :widget_data="widget_data" :item="widget_data.reviews[+index + i]" />
                        </v-sheet>
                    </v-col> 
                    </template> 
                </template> 
                </v-row> 
            </v-carousel-item> 
            </template> 
        </v-carousel>

        <div class="clearfix"></div>
    </div>
</template>

<script>
import Header from '@/views/panel/Business/Widgets/Render/Header.vue';
import HeaderAggregate from '@/views/panel/Business/Widgets/Render/HeaderAggregate.vue';
import Review from '@/views/panel/Business/Widgets/Render/Review.vue';

export default {
    props: ["widget_data", "fw"],

    components: {
        Header,
        HeaderAggregate,
        Review
    },

    data () {
        return {
            destroyed: false
        }
    },

    methods: {
        openReviewFunnel() {
            window.open(this.widget_data.review_funnel_link);
        },

        autoSpanNext() {
            if (this.destroyed)
                return;

            let self = this;
            if (this.widget_data.widget.settings.auto_play_span) {
                if (this.widget_data.widget.settings.auto_play_span != "No autoplay") {
                    let autoPlaySeconds = parseFloat(this.widget_data.widget.settings.auto_play_span.replace("s", ""));

                    setTimeout(function() {
                        if (this.destroyed)
                            return;

                        document.querySelector(".v-window__next button").click()
                        self.autoSpanNext();
                    }, (autoPlaySeconds * 1000));
                }
            }
        }
    },

    computed: {
        slider_columns() {
            if (this.widget_data.widget.settings.reviews_per_page <= 4)
                return parseInt(this.widget_data.widget.settings.reviews_per_page);

            // return 3;

            if (this.$vuetify.breakpoint.xl) {
                return 4;
            }

            if (this.$vuetify.breakpoint.lg) {
                return 3;
            }

            if (this.$vuetify.breakpoint.md) {
                return 2;
            }

            return 1;
        }
    },

    mounted() {
        this.autoSpanNext();
    },

    destroyed() {
        this.destroyed = true;
    }
}
</script>

<style type="text/css" scoped>
    .embed-slider-wrapper {
        width: 100%;
        padding-bottom: 10px;
    }

    .v-carousel__controls {
        display: none !important;
    }

    .embed-slider-wrapper .v-window-item {
        padding-left: 50px;
        padding-right: 50px;
    }
</style>