<template>
    <div class="embed-slider-wrapper pl-3 pr-3" :style="
        'background-color: ' + ((widget_data.widget.settings.background_type == 'Transparent') ? 'transparent' : widget_data.widget.settings.background_color) + ';'
    ">
        <div style="position: relative; box-sizing: border-box; height: 280px;">
            <div style="position: absolute; width: 230px; border: solid 1px #CCCCCC; padding: 10px; text-align: center; min-height: 200px; height: calc(100% - 40px); margin-top: 4px;">
                <div style="zoom: 0.7">
                    <Header :widget_data="widget_data" />
                </div>

                <v-rating
                    class="reviews-rating -mt-3 mb-6"
                    :value="parseFloat(widget_data.average_rating)"
                    :color="widget_data.widget.settings.star_color"
                    dense
                    half-increments
                    readonly
                    size="35"
                ></v-rating>

                <div v-show="widget_data.widget.settings.show_leave_a_review_button == 1" class="pt-0 mb-5" style="width: 160px; margin: auto; margin-bottom: 20px;">
                    <div class="border-1 border-blue text-primary text-xs text-bold px-4 py-2 rounded cursor-pointer" @click="openReviewFunnel()" style="margin-top: -5px;"><i class="mdi mdi-star mr-2"> </i> WRITE A REVIEW</div>
                </div>
            </div>

            <div style="position: absolute; margin-left: 230px; padding-left: 20px; min-height: 250px">
                <v-carousel height="230">
                    <template v-for="(item, index) in widget_data.reviews"> 
                    <v-carousel-item v-if="(index + 1) % slider_columns === 1 || slider_columns === 1" 
                                    :key="index"
                                    style="height: 100%;"
                    > 
                        <v-row class="flex-nowrap" style="height: 100%;"> 
                        <template v-for="(n,i) in slider_columns"> 
                            <template v-if="(+index + i) < widget_data.reviews.length"> 
                            <v-col :key="i" style="height: 100%;">
                                <v-sheet v-if="(+index + i) < widget_data.reviews.length" 
                                        height="100%"
                                        color="transparent"
                                >
                                    <Review :widget_data="widget_data" :item="widget_data.reviews[+index + i]" />
                                </v-sheet>
                            </v-col> 
                            </template> 
                        </template> 
                        </v-row> 
                    </v-carousel-item> 
                    </template> 
                </v-carousel>
            </div>

            <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>
import Header from '@/views/panel/Business/Widgets/Render/Header.vue';
import HeaderAggregate from '@/views/panel/Business/Widgets/Render/HeaderAggregate.vue';
import Review from '@/views/panel/Business/Widgets/Render/Review.vue';

export default {
    props: ["widget_data", "fw"],

    components: {
        Header,
        HeaderAggregate,
        Review
    },

    methods: {
        openReviewFunnel() {
            window.open(this.widget_data.review_funnel_link);
        }
    },

    computed: {
        slider_columns() {
            return 1;
        }
    },

    mounted() {
        repositionPrevNext();
        
        function repositionPrevNext() {
            try {
                document.querySelector(".v-window__prev").style.top = 'auto';
                document.querySelector(".v-window__prev").style.bottom = '1px';
                document.querySelector(".v-window__prev").style.left = 'auto';
                document.querySelector(".v-window__prev").style.right = '55px';

                document.querySelector(".v-window__next").style.top = 'auto';
                document.querySelector(".v-window__next").style.bottom = '1px';

                document.querySelector(".v-carousel").style.height = '250px';

                // document.querySelector(".embed-slider-wrapper .v-window__container").style.paddingBottom = '50px';
            }
            catch (e) {
                setTimeout(function() {
                    repositionPrevNext();
                }, 100);
            }
        }
    }
}
</script>

<style type="text/css" scoped>
    .embed-slider-wrapper {
        width: 100%;
        max-width: 800px;
        padding-bottom: 10px;
        margin: auto;
    }

    .v-carousel__controls {
        display: none !important;
    }

    .embed-slider-wrapper .v-window-item {
        padding-left: 0px;
        padding-right: 0px;
    }
</style>