<template>
    <div
        class="header-aggregate"
            :style="
                'margin-left: ' + widget_data.widget.settings.padding_between_reviews + ';' +
                'margin-bottom: ' + widget_data.widget.settings.padding_between_reviews + ';'
        "
    >
        <v-card flat outlined :color="((list) ? '#FFFFFF' : 'transparent')" style="text-align: left !important;">
            <v-card-text :style="'color: ' + widget_data.widget.settings.text_color">
                <div v-show="widget_data.widget.settings.show_leave_a_review_button == 1" class="float-right pt-5 pr-2">
                    <div class="border-1 border-blue text-primary text-xs text-bold px-4 py-2 rounded cursor-pointer float-right" @click="openReviewFunnel()" style="margin-top: -5px;"><i class="mdi mdi-star mr-2"> </i> WRITE A REVIEW</div>
                </div>
                <div class="reviews-average">
                    {{ widget_data.average_rating }}
                    <span style="font-size: 14px; font-weight: normal;">
                        {{ widget_data.total_reviews }} reviews
                    </span>
                </div>

                <v-rating
                    class="reviews-rating"
                    :value="parseFloat(widget_data.average_rating)"
                    :color="widget_data.widget.settings.star_color"
                    dense
                    half-increments
                    readonly
                    size="25"
                ></v-rating>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    props: ["widget_data", "list"],

    methods: {
        openReviewFunnel() {
            window.open(this.widget_data.review_funnel_link);
        }
    }
}
</script>

<style type="text/css" scoped>
    .header-aggregate {
        background: rgb(250, 250, 250);
        line-height: 26px;
        border-radius: 10px;
    }
</style>