<template>
    <div class="badge-5-wrapper" :style="((widget_data.widget.settings.badge_display == 'Embed' ? 'margin: auto;': ''))">
        <v-card
            elevation="2"
            class="reviews-badge"
            @click="$parent.showDialog()"
            rounded
            :style="'border-radius: 15px !important; padding-bottom: 0px; background-color: ' + ((widget_data.widget.settings.background_type == 'Transparent') ? 'transparent' : widget_data.widget.settings.background_color) + '; color: ' + widget_data.widget.settings.text_color + ';'"
        >
            <div class="float-left pl-4" style="padding-top: 6px;">
                <img v-for="(item, index) in widget_data.widget.settings.listings" :key="index" v-show="listingEnabled(item.id)"
                    :src="getIconUrl(item.url)"
                    class="listing-img"
                    :style="((widget_data.widget.settings.platforms.length == 1) ? 'margin-right: 0px;' : '')"
                >
            </div>

            <div class="float-left pl-6">
                <div class="reviews-average" style="display: inline-block; font-size: 22px;">
                    {{ widget_data.average_rating }}
                </div>

                <div style="display: inline-block; vertical-align: super;">
                    <v-rating
                        class="reviews-rating"
                        :value="parseFloat(widget_data.average_rating)"
                        :color="widget_data.widget.settings.star_color"
                        dense
                        half-increments
                        readonly
                        size="20"
                    ></v-rating>
                </div>
            </div>

            <div class="float-left text-sm pl-5" style="padding-top: 10px;">{{ widget_data.total_reviews }} reviews</div>

            <div class="clearfix"></div>
        </v-card>

        <div v-if="widget_data.widget.subscription.stripe_id == 'starter'" class="reviews-footer" :style="'color: ' + widget_data.widget.settings.footer_text_color + '; text-align: center;'">
            Powered by <a href="https://reviewgrower.com/" target="review-grower-external" :style="'color: ' + widget_data.widget.settings.footer_link_color + ';'">Review Grower</a>
        </div>
    </div>
</template>

<script>
import Header from '@/views/panel/Business/Widgets/Render/Header.vue';
import Review from '@/views/panel/Business/Widgets/Render/Review.vue';
import urlParser from 'url-parse'

export default {
    props: ["widget_data", "fw"],

    components: {
        Header,
        Review
    },
    
    methods: {
        getHostname(url) {
            return urlParser(url).hostname.replace("www.", "")
        },
        getPathname(url) {
            return urlParser(url).pathname
        },
        getIconUrl(url) {
            return require('@/assets/images/review-services/www.' + this.getHostname(url) + ".png");
        },
        listingEnabled(id) {
            console.log('id', id);
            console.log('this.widget_data.widget.settings.platforms', this.widget_data.widget.settings.platforms);
            for (var i in this.widget_data.widget.settings.platforms) {
                if (this.widget_data.widget.settings.platforms[i] == id)
                    return true;
            }

            return false;
        }
    }
}
</script>

<style type="text/css" scoped>
    .badge-5-wrapper {
        width: 400px;
        padding-bottom: 10px;
    }

    .bottom-border {
        width: calc(100% + 3px);
        margin-bottom: -2px;
        margin-top: 10px;
        margin-left: -1px;
        color: #FFFFFF;
        padding: 8px;
        text-align: center;
        font-size: 15px;
    }

    .listing-img {
        width: 30px;
        height: 30px;
        display: inline-block;
        margin-right: -10px;
        border-radius: 50%;
        border: solid 3px #FFFFFF;
    }
</style>