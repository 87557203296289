import Vue from 'vue'
import Router from 'vue-router'

import WidgetsRender from './views/panel/Business/Widgets/Render/Index.vue'
import ContactWidgetsRender from './views/panel/Business/ContactWidgets/Render/Index.vue'
import LocalReconWidgetsRender from './views/local-recon/WidgetsRender.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: function(to) {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: "/widgets/render/:widget_token",
      name: 'widgets_render',
      // component: () => import(/* webpackChunkName: "render_widget" */ './views/panel/Business/Widgets/Render/Index.vue')
      component: WidgetsRender
    },
    {
      path: '/widgets/contact/render/:widget_token',
      name: 'contact_widgets_render',
      component: ContactWidgetsRender
    },
    {
      path: '/local-recon/widgets/render/:widget_token',
      name: 'widgets_render_local_recon',
      // component: () => import(/* webpackChunkName: "render_lr_widget" */ './views/local-recon/WidgetsRender.vue')
      component: LocalReconWidgetsRender
    },
  ]
})
